.fade-in {
  animation: fadeIn 2.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 769px) {
  .slide {
    animation: slideDown 2s;
  }
}

@media screen and (max-width: 768px) {
  .slide {
    animation: slideRight 2s;
  }
}

@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
